import "./doneaza.css";

import { useEffect, useState } from "react";
import * as MyPOSEmbedded from "mypos-embedded-checkout";

import Header from "../../layouts/header";
import banner from "../../assets/photos/strength-people-hands-success-meeting.jpg";
import Button2 from "../../components/buttons/button2";

export default function DoneazaPage() {
  useEffect(() => window.scrollTo(0, 0), []);

  const paymentParams = {
    sid: "000000000000010",
    ipcLanguage: "en", //Checkout page language (ISO 2 format).
    walletNumber: "61938166610",
    amount: 10,
    currency: "RON",
    orderID: Math.random().toString(36).substr(2, 9),
    urlNotify: MyPOSEmbedded.IPC_URL + "/client/ipcNotify", // Warning: use your own url to verify your payment!
    urlOk: window.location.href, // Optional - required only for iDeal
    urlCancel: window.location.href, // Optional - required only for iDeal
    keyIndex: 1,
    cartItems: [
      {
        article: "Donatie OSM Cluj",
        quantity: 1,
        price: 10,
        currency: "RON",
      },
    ],
  };

  const callbackParams = {
    isSandbox: true,
    onSuccess: function (data) {
      console.log("success callback");
      console.log(data);
    },
    onError: function () {
      console.log("error");
    },
  };

  const amounts = [5, 10, 50, 100, 200, 500];
  const [amount, setAmount] = useState(null);
  let createdCheckout = false;

  useEffect(() => {
    if (!amount) return;
    if (createdCheckout) return;
    createdCheckout = true;

    MyPOSEmbedded.createPayment(
      "myPOSEmbeddedCheckout",
      paymentParams,
      callbackParams
    );
  }, [amount]);

  return (
    <div id="doneaza">
      <Header img={banner} top={150}>
        <h1 className="title">Susține-ne</h1>
      </Header>
      <section className="myPOS">
        <h4 style={{ textAlign: "center", margin: "30px 0px" }}>
          Donează prin card bancar
        </h4>
        <div className="container">
          {!amount && (
            <div className="select-amount">
              {amounts.map((value) => (
                <Button2
                  type="button"
                  click={() => {
                    setAmount(value);
                  }}
                >
                  {value} RON
                </Button2>
              ))}
            </div>
          )}
          <div id="myPOSEmbeddedCheckout"></div>
        </div>
      </section>
      <hr style={{ "--color": "var(--primary)" }} />
      <section className="form230">
        <h4 style={{ textAlign: "center", margin: "30px 0px" }}>
          Donează 3.5% folosind formularul 230
        </h4>
        <iframe
          src="https://formular230.ro/organizatia-studentilor-medicinisti-din-cluj"
          title="Formular 230"
        />
      </section>
    </div>
  );
}
