import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./navigation.css";

import NavigationLink, { NavigationLinkExtern } from "./navigationLink";
import Menu from "./menu/";

import logo from "../../assets/photos/banner-edited.png";
import menuLogo from "../../assets/photos/menu-logo.png";

import { PATH, LINKS } from "../../const/const";

import useWindowDimensions from "../../api/hooks/useWindowDimensions";

function Navigation() {
  const [isMenuVisible, setMenuVisibility] = useState(false);

  const [fullscreenMenu, setFullscreenMenu] = useState(false);
  const windowDimensions = useWindowDimensions();
  useEffect(() => {
    if (windowDimensions.width < 1150) setFullscreenMenu(true);
    else setFullscreenMenu(false);
  }, [windowDimensions]);

  return (
    <div id="navigation">
      <div className="relative-container">
        <div className="container flex row">
          <Link to="/">
            <div className="logo-container flex">
              <img src={logo} alt="logo" />
            </div>
          </Link>
          {/* <div className="links-container small flex row">
            <NavigationLinkExtern
              padding="50px"
              href={LINKS.tachycardia}
              highlight={true}
            >
              Tachycardia - Back to School Party
            </NavigationLinkExtern>
          </div> */}
          <div
            className="menu-icon-container flex"
            onMouseEnter={(e) => {
              if (!fullscreenMenu) setMenuVisibility(true);
              e.stopPropagation();
            }}
            onMouseLeave={() => {
              if (!fullscreenMenu) setMenuVisibility(false);
            }}
            onClick={(e) => {
              if (fullscreenMenu) {
                setMenuVisibility(!isMenuVisible);
                window.scrollTo(0, 0);
                e.stopPropagation();
              }
            }}
          >
            <img src={menuLogo} alt="logo" />
          </div>
          <div className="links-container flex row">
            {/* <NavigationLinkExtern
              padding="50px"
              href={LINKS.tachycardia}
              highlight={true}
            >
              Tachycardia - Back to school party
            </NavigationLinkExtern> */}
            <NavigationLink padding="50px" href={PATH.evenimente.index}>
              Evenimente
            </NavigationLink>
            <NavigationLink padding="50px" href={PATH.resurse.index}>
              Resurse
            </NavigationLink>
            <NavigationLink padding="50px" href={PATH.proiecte.index}>
              Proiecte
            </NavigationLink>
            <NavigationLink padding="50px" href={PATH.contact.index}>
              Contact
            </NavigationLink>
          </div>
        </div>
        <Menu isVisible={isMenuVisible} setVisibility={setMenuVisibility} />
      </div>
    </div>
  );
}

export default Navigation;
